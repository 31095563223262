<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 内容 -->
        <div
            class="body"
            slot="body"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAddExpress"
                    >
                        新增快递公司
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="expressName"
                    label="快递公司名称"
                    min-width="100"
                />
                <el-table-column
                    prop="expressCode"
                    label="快递公司代码"
                    min-width="100"
                />
                <el-table-column
                    prop="expressWebsite"
                    label="快递公司网址"
                    min-width="100"
                />
                <el-table-column
                    prop="expressDesc"
                    label="快递公司描述"
                    min-width="100"
                />
                <el-table-column
                    prop="thirdPlatformExpressCode"
                    label="第三方快递编码"
                    min-width="100"
                />
                <!--<el-table-column
prop="orderNum"
label="排序"
min-width="100"
/>-->
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onEditWaybillTemplate(scope.row)"
                        >
                            设置
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onDeleteExpress(scope.row)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            title="新增快递公司"
            center
            width="1200px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddExpressCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="addDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="addDialog.queryFormModel"
            >
                <el-form-item
                    prop="expressName"
                    label="快递公司名称"
                >
                    <el-input
                        v-model="addDialog.queryFormModel.expressName"
                        placeholder="请输入快递公司名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="expressCode"
                    label="快递公司代码"
                >
                    <el-input
                        v-model="addDialog.queryFormModel.expressCode"
                        placeholder="请输入快递公司代码"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onAddDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetAddDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="addDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="expressId"
                    ref="addDialogTable"
                    @selection-change="onAddDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="36"
                    />
                    <el-table-column
                        prop="expressName"
                        label="快递公司名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="expressCode"
                        label="快递公司代码"
                        min-width="100"
                    />
                    <el-table-column
                        prop="expressWebsite"
                        label="快递公司网址"
                        min-width="100"
                    />
                    <el-table-column
                        prop="expressDesc"
                        label="快递公司描述"
                        min-width="100"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="addDialog.pagination"
                        @input="onAddDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddExpressConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddExpressCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <!-- 面单模板 -->
        <el-dialog
            title="设置"
            center
            width="1200px"
            :visible.sync="waybillTemplateDialog.isVisible"
            @closed="onWaybillTemplateDialogCancel"
        >
            <el-form
                ref="waybillTemplateForm"
                size="small"
                :model="waybillTemplateDialog.formModel"
                :rules="waybillTemplateDialog.formRules"
            >
                <el-form-item
                    prop="thirdPlatformExpressCode"
                    label="第三方快递编码"
                    label-width="8em"
                >
                    <el-input
                        v-model="waybillTemplateDialog.formModel.thirdPlatformExpressCode"
                        auto-complete="off"
                    />
                </el-form-item>
                <!--                <el-form-item>
<el-tabs
v-model="waybillTemplateDialog.selectTab"
type="card"
editable
@edit="handleTabsEdit"
>
<el-tab-pane
v-for="item in waybillTemplateDialog.templateList"
:key="item.id"
:label="item.name"
:name="item.id"
>
<el-form-item
label="模板名称"
label-width="6em"
>
<el-input
v-model="item.name"
auto-complete="off"
/>
</el-form-item>
<el-form-item
label="模板配置"
label-width="6em"
>
<el-input
type="textarea"
v-model="item.config"
auto-complete="off"
/>
</el-form-item>
</el-tab-pane>
</el-tabs>
</el-form-item>-->
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onWaybillTemplateDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onWaybillTemplateDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ShopExpress',
    mixins: [pagesMixin],
    data() {
        return {
            queryFormModel: {},
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    expressName: '', // 快递公司名称
                    expressCode: '', // 快递公司代码
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            // 面单模板
            waybillTemplateDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    expressId: '',
                    thirdPlatformExpressCode: '',
                },
                // 表单校验规则
                formRules: {},
                templateList: [],
                selectTab: '',
                tabIndex: 0,
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData() {
            return this.$api.Sp.ShopExpress.data().then(json => {
                const res = json.data;
                this.tableData = res.data;
            });
        },
        /* -- 事件 -- */
        onAddExpress() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                this.onAddDialogQuery();
                const { addDialogTable } = this.$refs;
                // 初始化已选择数据
                if (addDialogTable) {
                    addDialogTable.clearSelection();
                }
                // this.tableData = [this.addDialog.tableData[0]];
                this.tableData.forEach(item => {
                    addDialogTable.toggleRowSelection(item, true);
                });
            });
        },
        onAddDialogQuery(pagination) {
            return this.$api.Rs.Express.data({
                ...this.addDialog.queryFormModel,
                ...pagination,
                isAvailable: 1,
            }).then(json => {
                const res = json.data;
                res.data.forEach(item => {
                    item.expressId = item.id;
                    item.id = null;
                });
                this.addDialog.tableData = res.data;
                this.addDialog.pagination = res.pagination;
            });
        },
        onAddDialogSelectionChange(val) {
            this.addDialog.currentTableSelect = val;
        },
        onResetAddDialog() {
            this.$refs.addDialogQueryForm.resetFields();
        },
        onAddExpressConfirm() {
            this.$api.Sp.ShopExpress.save({
                jsonStr: JSON.stringify(this.addDialog.currentTableSelect),
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.getListData();
                this.addDialog.isVisible = false;
            });
        },
        onAddExpressCancel() {
            this.addDialog.isVisible = false;
        },
        onDeleteExpress(row) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Sp.ShopExpress.delete({ ids: row.id }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.getListData();
                });
            });
        },
        // 关联面单模板
        onEditWaybillTemplate(row) {
            this.waybillTemplateDialog.formModel.expressId = row.expressId;
            this.waybillTemplateDialog.formModel.thirdPlatformExpressCode = row.thirdPlatformExpressCode;
            this.$api.Sp.ShopExpress.getByExpressId({ expressId: row.expressId }).then(json => {
                this.waybillTemplateDialog.templateList = JSON.parse(json.data.data.waybillTemplate) || [];
                if (this.waybillTemplateDialog.templateList.length === 0) {
                    this.waybillTemplateDialog.templateList.push({
                        id: this.$utils.getKey(),
                        name: '面单模板1',
                        config: '',
                    });
                }
                this.waybillTemplateDialog.tabIndex = this.waybillTemplateDialog.templateList.length;
                this.waybillTemplateDialog.selectTab = this.waybillTemplateDialog.templateList[0].id;
                this.waybillTemplateDialog.isVisible = true;
            });
        },
        onWaybillTemplateDialogConfirm() {
            const { waybillTemplateForm } = this.$refs;
            waybillTemplateForm.validate().then(() => {
                this.$api.Sp.ShopExpress.saveShopExpress({
                    expressId: this.waybillTemplateDialog.formModel.expressId,
                    waybillTemplate: JSON.stringify(this.waybillTemplateDialog.templateList),
                    thirdPlatformExpressCode: this.waybillTemplateDialog.formModel.thirdPlatformExpressCode,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.waybillTemplateDialog.isVisible = false;
                    this.getListData();
                });
            });
        },
        onWaybillTemplateDialogCancel() {
            const { waybillTemplateForm } = this.$refs;
            waybillTemplateForm.resetFields();
            this.waybillTemplateDialog.isVisible = false;
        },
        handleTabsEdit(targetName, action) {
            if (action === 'add') {
                let newTabName = `${(this.waybillTemplateDialog.tabIndex += 1)}`;
                newTabName = `面单模板${newTabName}`;
                const key = this.$utils.getKey();
                this.waybillTemplateDialog.templateList.push({
                    name: newTabName,
                    id: key,
                    config: '',
                });
                this.waybillTemplateDialog.selectTab = key;
            }
            if (action === 'remove') {
                const tabs = this.waybillTemplateDialog.templateList;
                let activeName = this.waybillTemplateDialog.selectTab;
                if (activeName === targetName) {
                    tabs.forEach((tab, index) => {
                        if (tab.id === targetName) {
                            const nextTab = tabs[index + 1] || tabs[index - 1];
                            if (nextTab) {
                                activeName = nextTab.id;
                            }
                        }
                    });
                }

                this.waybillTemplateDialog.selectTab = activeName;
                this.waybillTemplateDialog.templateList = tabs.filter(tab => tab.id !== targetName);
            }
        },
    },
    created() {
        this.getListData();
    },
};
</script>

<style lang="scss">
</style>
