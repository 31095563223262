<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <el-row>
            <el-col :span="12">
                <el-form
                    ref="addForm"
                    size="small"
                    :model="formModel"
                    :rules="formRules"
                >
                    <el-form-item
                        prop="shopName"
                        :label="$t('pageKey142', '店铺名称')"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.shopName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="shopLogo"
                        :label="$t('pageKey143', '店铺Logo')"
                        label-width="13em"
                    >
                        <upload
                            validate-md5
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="1"
                            v-model="formModel.shopLogo"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="shopShareLogo"
                        :label="$t('pageKey144', '店铺分享Logo')"
                        label-width="13em"
                    >
                        <upload
                            validate-md5
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="1"
                            v-model="formModel.shopShareLogo"
                        />
                    </el-form-item>
                    <!--                    <el-form-item
                                  prop="principalName"
                                  label="联系人姓名"
                                  label-width="13em"
                              >
                                  <el-input
                                      v-model="formModel.principalName"
                                      auto-complete="off"
                                  />
                              </el-form-item>
                              <el-form-item
                                  prop="principalPosition"
                                  label="联系人职务"
                                  label-width="13em"
                              >
                                  <el-input
                                      v-model="formModel.principalPosition"
                                      auto-complete="off"
                                  />
                              </el-form-item>
                              <el-form-item
                                  prop="principalMobile"
                                  label="联系人电话"
                                  label-width="13em"
                              >
                                  <el-input
                                      v-model="formModel.principalMobile"
                                      auto-complete="off"
                                  />
                              </el-form-item>
                              <el-form-item
                                  prop="principalEmail"
                                  label="联系人邮箱"
                                  label-width="13em"
                              >
                                  <el-input
                                      v-model="formModel.principalEmail"
                                      auto-complete="off"
                                  />
                              </el-form-item>
                              <el-form-item
                                  prop="principalQq"
                                  label="联系人QQ"
                                  label-width="13em"
                              >
                                  <el-input
                                      v-model="formModel.principalQq"
                                      auto-complete="off"
                                  />
                              </el-form-item>
                              <el-form-item
                                  prop="shopRegionId"
                                  label="店铺地址"
                                  label-width="13em"
                              >
                                  <CascaderPicker
                                      :api-class="selectApi"
                                      v-model="formModel.shopRegionId"
                                      :p-id="2"
                                      :min-lv="2"
                                  />
                              </el-form-item>
                              <el-form-item
                                  prop="shopAddress"
                                  label="详细地址"
                                  label-width="13em"
                              >
                                  <el-input
                                      v-model="formModel.shopAddress"
                                      auto-complete="off"
                                  />
                              </el-form-item>-->
                    <el-form-item
                        prop="shopDesc"
                        :label="$t('pageKey145', '店铺描述')"
                        label-width="13em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.shopDesc"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <!--                    <el-form-item
                                  prop="freightMode"
                                  label="运费模式"
                                  label-width="13em"
                              >
                                  <dictionaries-picker
                                      type="freightMode"
                                      v-model="formModel.freightMode"
                                  />
                              </el-form-item>-->
                    <el-form-item
                        prop="goodsCatStyle"
                        :label="$t('pageKey146', '商品分类样式')"
                        label-width="13em"
                    >
                        <el-select
                            v-model="formModel.goodsCatStyle"
                            :placeholder="$t('pageKey101', '请选择')"
                        >
                            <el-option
                                :label="$t('pageKey8', '不启用')"
                                :value="99"
                            />
                            <el-option
                                :label="$t('pageKey147', '仅分类')"
                                :value="1"
                            />
                            <el-option
                                :label="$t('pageKey148', '分类+商品')"
                                :value="2"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        prop="isAllowMoreLevel"
                        :label="$t('pageKey149', '二级分类')"
                        label-width="13em"
                    >
                        <el-switch
                            v-model="formModel.isAllowMoreLevel"
                        />
                        {{ $t('pageKey150', '开启二级分类(备注：商品展示时默认只显示一级分类，如需显示二级分类则勾选此项)') }}
                    </el-form-item>
                    <el-form-item
                        prop="isAllowOrder"
                        :label="$t('pageKey151', '是否允许下单')"
                        label-width="13em"
                    >
                        <el-switch
                            v-model="formModel.isAllowOrder"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="orderAmountLimit"
                        label="每日下单金额限制"
                        label-width="13em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="2"
                            v-model.number="formModel.orderAmountLimit"
                        />(为0不限制)
                    </el-form-item>
                    <el-form-item
                        prop="isShowNotice"
                        :label="$t('pageKey152', '是否显示公告')"
                        label-width="13em"
                    >
                        <el-switch
                            v-model="formModel.isShowNotice"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="shopNoticeImage"
                        :label="$t('pageKey153', '公告图片')"
                        label-width="13em"
                        v-if="formModel.isShowNotice"
                    >
                        <upload
                            validate-md5
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="1"
                            v-model="formModel.shopNoticeImage"
                        />
                    </el-form-item>
                    <el-form-item
                        v-else
                        v-show="formModel.isShowNotice"
                    >
                        <!--为了不用给 el-form-item 一个个加 key 的技巧用法-->
                    </el-form-item>
                    <el-form-item
                        prop="shopNoticeLink"
                        :label="$t('pageKey154', '公告链接')"
                        label-width="13em"
                        v-if="formModel.isShowNotice"
                    >
                        <el-input
                            v-model="formModel.shopNoticeLink"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        v-else
                        v-show="formModel.isShowNotice"
                    >
                        <!--为了不用给 el-form-item 一个个加 key 的技巧用法-->
                    </el-form-item>
                    <!--                    <el-form-item
                                  prop="isShowRMBAmount"
                                  label="是否显示人民币"
                                  label-width="13em"
                              >
                                  <el-switch
                                      v-model="formModel.isShowRMBAmount"
                                  />
                              </el-form-item>
                              <el-form-item
                                  prop="RMBAmountRate"
                                  label="人民币汇率"
                                  label-width="13em"
                                  v-if="formModel.isShowRMBAmount"
                              >
                                  <el-input-number
                                      controls-position="right"
                                      :min="0"
                                      :precision="4"
                                      v-model.number="formModel.RMBAmountRate"
                                  />
                              </el-form-item>-->
                    <!--                    <template v-if="formModel.goodsSendMode.includes('2')">
                                  <el-form-item
                                      prop="sendConsignor"
                                      label="发件人"
                                      label-width="13em"
                                  >
                                      <el-input
                                          v-model="formModel.sendConsignor"
                                          auto-complete="off"
                                      />
                                  </el-form-item>
                                  <el-form-item
                                      prop="sendTel"
                                      label="发件人电话"
                                      label-width="13em"
                                  >
                                      <el-input
                                          v-model="formModel.sendTel"
                                          auto-complete="off"
                                      />
                                  </el-form-item>
                                  <el-form-item
                                      prop="isStoreEditSendInfo"
                                      label="门店是否维护发件人信息"
                                      label-width="13em"
                                  >
                                      <el-switch
                                          v-model="formModel.isStoreEditSendInfo"
                                      />
                                  </el-form-item>
                              </template>
                              <el-form-item
                                  prop="minWithdrawAmount"
                                  label="最低提现金额"
                                  label-width="13em"
                              >
                                  <el-input-number
                                      controls-position="right"
                                      :min="0"
                                      :precision="2"
                                      v-model.number="formModel.minWithdrawAmount"
                                  />
                              </el-form-item>
                              <el-form-item
                                  prop="withdrawPoundageRate"
                                  label="提现手续费(%)"
                                  label-width="13em"
                              >
                                  <el-input-number
                                      controls-position="right"
                                      :min="0"
                                      :max="100"
                                      :precision="2"
                                      v-model.number="formModel.withdrawPoundageRate"
                                  />
                              </el-form-item>
                              <el-form-item
                                  prop="marketCodeContacts"
                                  label="一物一码联系人"
                                  label-width="13em"
                                  v-if="userData.shop.isAuthMarketCode"
                              >
                                  <el-input
                                      v-model="formModel.marketCodeContacts"
                                      auto-complete="off"
                                  />
                              </el-form-item>
                              <el-form-item
                                  prop="marketCodeCouponId"
                                  label="一物一码优惠券ID"
                                  label-width="13em"
                                  v-if="userData.shop.isAuthMarketCode"
                              >
                                  <el-input
                                      v-model="formModel.marketCodeCouponId"
                                      auto-complete="off"
                                  />
                              </el-form-item>
                              <el-form-item
                                  prop="marketCodeWarnNum"
                                  label="一物一码最大查询次数"
                                  label-width="13em"
                                  v-if="userData.shop.isAuthMarketCode"
                              >
                                  <el-input-number
                                      controls-position="right"
                                      :min="1"
                                      :precision="0"
                                      v-model.number="formModel.marketCodeWarnNum"
                                  />
                              </el-form-item>-->
                    <el-form-item
                        prop="isShowEnterpriseWeChat"
                        :label="$t('pageKey155', '是否显示企业微信')"
                        label-width="13em"
                    >
                        <el-switch
                            v-model="formModel.isShowEnterpriseWeChat"
                        />
                    </el-form-item>
                    <el-form-item
                        key="enterpriseWeChatImage"
                        prop="enterpriseWeChatImage"
                        :label="$t('pageKey156', '企业微信图片')"
                        label-width="13em"
                        v-if="formModel.isShowEnterpriseWeChat"
                    >
                        <upload
                            validate-md5
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="1"
                            v-model="formModel.enterpriseWeChatImage"
                        />
                    </el-form-item>
                    <el-form-item
                        label-width="13em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            {{ $t('pageKey73', '保 存') }}
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ShopEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                shopName: '',
                shopLogo: [],
                shopShareLogo: [],
                principalName: '',
                principalPosition: '',
                principalMobile: '',
                principalEmail: '',
                principalQq: '',
                shopRegionId: '',
                shopAddress: '',
                shopDesc: '',
                freightMode: '',
                isAllowMoreLevel: false,
                isAllowOrder: '',
                orderAmountLimit: '',
                isShowNotice: '',
                shopNoticeImage: [],
                shopNoticeLink: '',
                isShowRMBAmount: false,
                RMBAmountRate: '',
                sendConsignor: '',
                sendTel: '',
                isStoreEditSendInfo: false,
                minWithdrawAmount: '',
                withdrawPoundageRate: '',
                goodsSendMode: [],
                marketCodeContacts: '',
                marketCodeCouponId: '',
                marketCodeWarnNum: '',
                goodsCatStyle: 1,
                isShowEnterpriseWeChat: false,
                enterpriseWeChatImage: [],
            },
            // 表单校验规则
            formRules: {
                shopName: {
                    required: true,
                    message: this.$t('pageKey157', '请输入店铺名称'),
                    trigger: 'blur',
                },
                shopLogo: {
                    type: 'array',
                    required: true,
                    message: this.$t('pageKey158', '请上传等级图片'),
                },
                principalName: {
                    required: true,
                    message: this.$t('pageKey130', '请输入联系人姓名'),
                    trigger: 'blur',
                },
                principalMobile: {
                    required: true,
                    message: this.$t('pageKey131', '请输入联系人电话'),
                    trigger: 'blur',
                },
                shopRegionId: {
                    required: true,
                    message: this.$t('pageKey159', '请选择店铺地址'),
                    trigger: 'blur',
                },
                shopAddress: {
                    required: true,
                    message: this.$t('pageKey160', '请输入店铺详细地址'),
                    trigger: 'blur',
                },
                freightMode: {
                    required: true,
                    message: this.$t('pageKey161', '请选择运费模式'),
                    trigger: 'blur',
                },
                isAllowMoreLevel: {
                    required: true,
                    message: this.$t('pageKey162', '请选择是否开启二级节点'),
                    trigger: 'blur',
                },
                shopNoticeImage: {
                    type: 'array',
                    required: true,
                    message: this.$t('pageKey163', '请上传公告图片'),
                },
                goodsCatStyle: {
                    required: true,
                    message: this.$t('pageKey164', '请选择商品分类样式'),
                },
            },
            selectApi: this.$api.Rs.Region,
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Sp.Shop.saveShopInfo({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                });
            });
        },
        onCancel() {
            this.$router.go(-1);
        },
        getShopInfo() {
            const { formModel } = this;
            this.$api.Sp.Shop.getShopInfo().then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res);
            });
        },
    },
    created() {
        this.getShopInfo();
    },
};
</script>

<style lang="scss">
</style>
