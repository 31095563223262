<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.approvalStatus"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="待审核"
                    name="10"
                />
                <el-tab-pane
                    label="审核通过"
                    name="100"
                />
                <el-tab-pane
                    label="审核不通过"
                    name="99"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="driverName"
                    label="配送员姓名"
                >
                    <el-input
                        v-model="queryFormModel.driverName"
                        placeholder="请输入配送员姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="driverMobile"
                    label="配送员电话"
                >
                    <el-input
                        v-model="queryFormModel.driverMobile"
                        placeholder="请输入配送员电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="driverLevelId"
                    label="配送员等级"
                >
                    <RemoteSelect
                        clearable
                        remote="/sp/shopDriverLevel/select"
                        :placeholder="$t('pageKey238', '请输入')"
                        :props="{id:'id',name:'levelName'}"
                        v-model="queryFormModel.driverLevelId"
                    />
                </el-form-item>
                <el-form-item
                    prop="driverStatus"
                    label="配送员状态"
                >
                    <dictionaries-picker
                        type="driverStatus"
                        v-model="queryFormModel.driverStatus"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="levelName"
                    label="配送员等级"
                    min-width="100"
                />
                <el-table-column
                    prop="driverName"
                    label="配送员姓名"
                    min-width="100"
                />
                <el-table-column
                    prop="driverMobile"
                    label="配送员电话"
                    min-width="100"
                />
                <el-table-column
                    prop="totalIncome"
                    label="总收益金额"
                    min-width="100"
                    v-if="+queryFormModel.approvalStatus === 100"
                />
                <el-table-column
                    prop="cashedAmount"
                    label="已提现金额"
                    min-width="100"
                    v-if="+queryFormModel.approvalStatus === 100"
                />
                <el-table-column
                    prop="cashingAmount"
                    label="提现中金额"
                    min-width="100"
                    v-if="+queryFormModel.approvalStatus === 100"
                />
                <el-table-column
                    prop="driverStatusName"
                    label="配送员状态"
                    min-width="100"
                    v-if="+queryFormModel.approvalStatus === 100"
                />
                <el-table-column
                    prop="approvalRemarks"
                    key="approvalRemarks"
                    label="审核备注"
                    min-width="100"
                    v-if="+queryFormModel.approvalStatus === 99"
                />
                <el-table-column
                    prop="approvalUserName"
                    key="approvalUserName"
                    label="审核人姓名"
                    min-width="100"
                    v-if="+queryFormModel.approvalStatus === 99"
                />
                <el-table-column
                    key="approvalTime"
                    label="审核时间"
                    width="135"
                    v-if="+queryFormModel.approvalStatus === 99"
                >
                    <template slot-scope="scope">
                        {{ scope.row.approvalTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    key="updateTime"
                    label="提交时间"
                    width="135"
                    v-if="+queryFormModel.approvalStatus === 10"
                >
                    <template slot-scope="scope">
                        {{ scope.row.updateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row)"
                            v-if="scope.row.approvalStatus === 100"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/sp/shopDriver/shopDriverSettleIn/detail/' + scope.row.id)"
                        >
                            {{ scope.row.approvalStatus === 10 ? '审核' : '查看' }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            :title="addDialog.formModel.id ? '编辑' : '新增'"
            center
            width="600px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="driverLevelId"
                    label="配送员等级"
                    label-width="8em"
                >
                    <RemoteSelect
                        clearable
                        remote="/sp/shopDriverLevel/select"
                        :placeholder="$t('pageKey238', '请输入')"
                        :props="{id:'id',name:'levelName'}"
                        v-model="addDialog.formModel.driverLevelId"
                    />
                </el-form-item>
                <el-form-item
                    prop="driverName"
                    label="配送员姓名"
                    label-width="8em"
                >
                    <el-input
                        v-model="addDialog.formModel.driverName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="driverMobile"
                    label="配送员手机号"
                    label-width="8em"
                >
                    <el-input
                        v-model="addDialog.formModel.driverMobile"
                        auto-complete="off"
                        :disabled="!!addDialog.formModel.id"
                    />
                </el-form-item>
                <el-form-item
                    prop="regionId"
                    label="联系地址"
                    label-width="8em"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="addDialog.formModel.regionId"
                        :p-id="2"
                        :min-lv="2"
                    />
                </el-form-item>
                <el-form-item
                    prop="detailAddress"
                    label="详细地址"
                    label-width="8em"
                >
                    <el-input
                        v-model="addDialog.formModel.detailAddress"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="driverStatus"
                    label="状态"
                    label-width="8em"
                >
                    <dictionaries-picker
                        type="driverStatus"
                        v-model="addDialog.formModel.driverStatus"
                    />
                </el-form-item>
                <el-form-item
                    prop="remarks"
                    label="备注"
                    label-width="8em"
                >
                    <el-input
                        type="textarea"
                        v-model="addDialog.formModel.remarks"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'GoodsReview',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                driverMobile: '', // 配送员电话
                driverName: '', // 配送员姓名
                approvalStatus: '10',
                driverStatus: '',
                driverTypeEq: 2,
                driverLevelId: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    driverName: '',
                    driverMobile: '',
                    password: '',
                    regionId: '',
                    detailAddress: '',
                    driverStatus: '',
                    remarks: '',
                    driverLevelId: '',
                },
                // 表单校验规则
                formRules: {
                    driverName: {
                        required: true,
                        message: '请输入配送员姓名',
                        trigger: 'blur',
                    },
                    driverMobile: {
                        required: true,
                        message: '请输入配送员手机号',
                        trigger: 'blur',
                    },
                    password: {
                        required: true,
                        message: '请输入密码',
                        trigger: 'blur',
                    },
                    driverStatus: {
                        required: true,
                        message: '请选择配送员状态',
                    },
                    driverLevelId: {
                        required: true,
                        message: '请选择配送员等级',
                    },
                },
            },
            selectApi: this.$api.Rs.Region,
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Sp.ShopDriver.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.$api.Sp.ShopDriver.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res);
            });
            this.addDialog.isVisible = true;
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Sp.ShopDriver.saveSettleIn({
                    ...this.addDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        onResetPwdClick(row) {
            this.$confirm('是否重置该用户密码！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Rs.User.resetPwd({
                    id: row.driverUserId,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
