<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <template
            slot="body"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>配送员信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="配送员姓名:"
                                label-width="8em"
                            >
                                {{ detail.driverName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="配送员手机号:"
                                label-width="8em"
                            >
                                {{ detail.driverMobile }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="配送员类型:"
                                label-width="8em"
                            >
                                {{ detail.driverTypeName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="配送员状态:"
                                label-width="8em"
                            >
                                {{ detail.driverStatusName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="审核状态:"
                                label-width="8em"
                                v-if="detail.driverType === 2"
                            >
                                {{ detail.approvalStatusName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="总收益金额:"
                                label-width="8em"
                                v-if="detail.driverType === 2 && detail.approvalStatus === 100"
                            >
                                {{ detail.totalIncome }}元
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="已提现金额:"
                                label-width="8em"
                                v-if="detail.driverType === 2 && detail.approvalStatus === 100"
                            >
                                {{ detail.cashedAmount }}元
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="提现中金额:"
                                label-width="8em"
                                v-if="detail.driverType === 2 && detail.approvalStatus === 100"
                            >
                                {{ detail.cashingAmount }}元
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                    v-if="detail.driverType === 2"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>身份证信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="身份证姓名:"
                                label-width="8em"
                            >
                                {{ detail.idCardName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="身份证号:"
                                label-width="8em"
                            >
                                {{ detail.idCardNo }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="身份证正面图:"
                                label-width="8em"
                            >
                                <ImageList
                                    :data="detail.idCardFrontUrl"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="身份证背面图:"
                                label-width="8em"
                            >
                                <ImageList
                                    :data="detail.idCardBackUrl"
                                />
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <!--                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                    v-if="detail.driverType === 2"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>银行卡信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="开户人姓名:"
                                label-width="8em"
                            >
                                {{ detail.bankCardName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="开户账号:"
                                label-width="8em"
                            >
                                {{ detail.bankCardNo }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="银行卡正面图:"
                                label-width="8em"
                            >
                                <ImageList
                                    :data="detail.bankCardFrontUrl"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="银行卡背面图:"
                                label-width="8em"
                            >
                                <ImageList
                                    :data="detail.bankCardBackUrl"
                                />
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>-->
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                    v-if="detail.driverType === 2 && detail.approvalStatus && detail.approvalStatus !== 10"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>审核信息</span>
                    </div>
                    <div class="item">
                        <el-col
                            :span="12">
                            <el-form-item
                                label="审核时间:"
                                label-width="9em"
                            >
                                {{ detail.approvalTime  | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="12">
                            <el-form-item
                                label="审核人:"
                                label-width="9em"
                            >
                                {{ detail.approvalUserName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item
                                label="审核不通过原因:"
                                label-width="9em"
                                v-if="detail.approvalStatus === 99"
                            >
                                {{ detail.approvalRemarks }}
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="detail.approvalStatus === 10"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>入驻审核</span>
                    </div>
                    <div class="item">
                        <el-form
                            ref="addForm"
                            size="small"
                            :model="formModel"
                            :rules="formRules"
                        >
                            <el-form-item
                                prop="approvalStatus"
                                label="审核是否通过:"
                                label-width="8em"
                            >
                                <el-select
                                    v-model="formModel.approvalStatus"
                                    placeholder="请选择"
                                >
                                    <el-option
                                        :key="99"
                                        label="审核不通过"
                                        :value="99"
                                    />
                                    <el-option
                                        :key="100"
                                        label="审核通过"
                                        :value="100"
                                    />
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                prop="approvalRemarks"
                                label="不通过原因:"
                                label-width="8em"
                                v-if="formModel.approvalStatus === 99"
                            >
                                <el-input
                                    type="textarea"
                                    v-model="formModel.approvalRemarks"
                                    auto-complete="off"
                                />
                            </el-form-item>
                        </el-form>
                    </div>
                </el-card>
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix">
                        <span>操作日志</span>
                    </div>
                    <el-col :span="24">
                        <el-col :span="22">
                            <el-form-item
                                label-width="5em"
                            >
                                <!-- 表格 -->
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    :data="detail.logList"
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="operateName"
                                        label="操作"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="operatorName"
                                        label="操作人"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="operateNote"
                                        label="操作说明"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        label="操作时间"
                                        width="135"
                                    >
                                        <template slot-scope="scope">
                                            {{ scope.row.operateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="remarks"
                                        label="备注"
                                        min-width="100"
                                    />
                                </el-table>
                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-card>
                <el-card
                    size="small"
                    class="ma-t"
                >
                    <el-form-item
                        label-width="13em"
                    >
                        <el-button
                            v-if="detail.approvalStatus === 10"
                            type="primary"
                            size="small"
                            @click="onConfirm()"
                        >
                            提交
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-card>
            </el-form>
        </template>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'GoodsReviewDetail',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                approvalStatus: '',
                approvalRemarks: '',
            },
            // 表单校验规则
            formRules: {
                approvalStatus: {
                    required: true,
                    message: '请选择审核是否通过',
                },
                approvalRemarks: {
                    required: true,
                    message: '请输入不通过原因',
                    trigger: 'blur',
                },
            },
            detail: {},
        };
    },
    methods: {
        init() {
            this.$api.Sp.ShopDriver.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.detail = res;
            });
        },
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$confirm('是否确定提交！', '温馨提示', {
                    type: 'warning',
                }).then(() => {
                    this.$api.Sp.ShopDriver.audit({
                        ...this.formModel,
                    }).then(json => {
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.$router.back();
                    });
                });
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
