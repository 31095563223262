<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <el-row>
            <el-col :span="12">
                <el-form
                    ref="addForm"
                    size="small"
                    :model="formModel"
                    :rules="formRules"
                >
                    <!--                    <el-form-item
                                            prop="billingRuleType"
                                            label="计费规则类型"
                                            label-width="12em"
                                        >
                                            <dictionaries-picker
                                                type="billingRuleType"
                                                v-model="formModel.billingRuleType"
                                            />
                                        </el-form-item>
                                        <el-form-item
                                            prop="fixedFreightAmount"
                                            label="固定运费金额"
                                            label-width="12em"
                                            v-if="+formModel.billingRuleType === 1"
                                        >
                                            <el-input-number
                                                controls-position="right"
                                                :min="0"
                                                :precision="2"
                                                v-model.number="formModel.fixedFreightAmount"
                                            />
                                        </el-form-item>-->
                    <el-form-item
                        prop="balanceExpansionRatio"
                        label="零钱转虚拟币膨胀比例"
                        label-width="12em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="2"
                            v-model.number="formModel.balanceExpansionRatio"
                        />
                        %
                    </el-form-item>
                    <el-form-item
                        prop="acceptOrderNeedPicking"
                        label="抢单是否需要拣货完成"
                        label-width="12em"
                    >
                        <el-switch
                            v-model="formModel.acceptOrderNeedPicking"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="acceptOrderDistance"
                        label="最远接单距离"
                        label-width="12em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="2"
                            v-model.number="formModel.acceptOrderDistance"
                        />
                        米（为0不限制）
                    </el-form-item>
                    <el-form-item
                        prop="acceptOrderNum"
                        label="最大同时接单数量"
                        label-width="12em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="0"
                            v-model.number="formModel.acceptOrderNum"
                        />
                        （为0不限制）
                    </el-form-item>
                    <el-form-item
                        prop="withdrawDayNum"
                        label="每日提现次数"
                        label-width="12em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="0"
                            v-model.number="formModel.withdrawDayNum"
                        />
                        （为0不限制）
                    </el-form-item>
                    <el-form-item
                        prop="isAutoAudit"
                        label="入驻是否免审核"
                        label-width="12em"
                    >
                        <el-switch
                            v-model="formModel.isAutoAudit"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="customerTel"
                        label="客服联系电话"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.customerTel"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        label-width="12em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            保 存
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ShopEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                billingRuleType: '',
                fixedFreightAmount: '',
                balanceExpansionRatio: 100,
                acceptOrderDistance: 0,
                acceptOrderNum: 0,
                withdrawDayNum: 0,
                isAutoAudit: false,
                acceptOrderNeedPicking: true,
                customerTel: '',
            },
            // 表单校验规则
            formRules: {
                billingRuleType: {
                    required: true,
                    message: '请选择计费规则类型',
                },
                fixedFreightAmount: {
                    required: true,
                    message: '请输入固定运费金额',
                    trigger: 'blur',
                },
                balanceExpansionRatio: {
                    required: true,
                    message: '请输入零钱提现膨胀比例',
                    trigger: 'blur',
                },
                acceptOrderDistance: {
                    required: true,
                    message: '请输入接单距离',
                    trigger: 'blur',
                },
                acceptOrderNum: {
                    required: true,
                    message: '请输入接单数量',
                    trigger: 'blur',
                },
                withdrawDayNum: {
                    required: true,
                    message: '请输入每日提现次数',
                    trigger: 'blur',
                },
                isAutoAudit: {
                    required: true,
                    message: '请选择入驻是否免审核',
                },
            },
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Sp.ShopDriverSet.saveShopDriverSet({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                });
            });
        },
        onCancel() {
            this.$router.go(-1);
        },
        getShopInfo() {
            const { formModel } = this;
            this.$api.Sp.ShopDriverSet.getShopDriverSet().then(json => {
                const res = json.data.data;
                if (res) {
                    this.$utils.formModelMerge(formModel, res);
                }
            });
        },
    },
    created() {
        this.getShopInfo();
    },
};
</script>

<style lang="scss">
</style>
